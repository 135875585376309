@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-Light.woff2') format('woff2'),
    url('RedHatDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-Regular.woff2') format('woff2'),
    url('RedHatDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-ExtraBold.woff2') format('woff2'),
    url('RedHatDisplay-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-ExtraBoldItalic.woff2') format('woff2'),
    url('RedHatDisplay-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-SemiBold.woff2') format('woff2'),
    url('RedHatDisplay-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-Italic.woff2') format('woff2'),
    url('RedHatDisplay-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-Light.woff2') format('woff2'),
    url('RedHatDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-LightItalic.woff2') format('woff2'),
    url('RedHatDisplay-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-SemiBoldItalic.woff2') format('woff2'),
    url('RedHatDisplay-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-MediumItalic.woff2') format('woff2'),
    url('RedHatDisplay-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-Black.woff2') format('woff2'),
    url('RedHatDisplay-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-BoldItalic.woff2') format('woff2'),
    url('RedHatDisplay-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-Bold.woff2') format('woff2'),
    url('RedHatDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-BlackItalic.woff2') format('woff2'),
    url('RedHatDisplay-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat';
  src: url('RedHatDisplay-Medium.woff2') format('woff2'),
    url('RedHatDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Red Hat';
}
