et-atoms-input {
  display: inline-block;
  margin-bottom: 2px;
  position: relative;

  & label {
    display: block;
    @apply text-secondary;
    cursor: text;
    width: fit-content;
    font-size: 16px;
    opacity: 1;
    transform: translate(24px, -2.3em);
    transform-origin: 24px 0;
    transition-duration: 0.3s;
    transition-property: transform, padding, font-size, background-color;
  }

  & input {
    box-sizing: border-box;
    display: block;
    @apply bg-primary-content border-neutral text-base-content;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    box-shadow: none;
    padding: 11px 24px;
    width: 100%;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;

    &[required] + label::after {
      content: ' *';
    }

    &[required] {
      &.ng-invalid {
        &.ng-dirty + label::after {
          @apply text-error;
        }
      }
    }
  }

  & input::placeholder {
    color: transparent;
  }

  & input:focus {
    border-color: transparent;
    @apply border-base-content shadow-base-content;
    box-shadow: 0 0 0 1px;
    outline: none;
  }

  & [etAtomsInputError] {
    position: absolute;
    bottom: 6px;
    left: 24px;
    @apply text-error;
    font-size: 14px;
    animation: etAtomsInputSlidein 0.3s;
    line-height: 22px;
    font-weight: 500;
  }

  & input:focus ~ label,
  & input:not(:placeholder-shown) ~ label {
    @apply bg-neutral-content;
    padding: 0 8px;
    transform: translate(14px, -3.7em) scale(0.8);
  }
}

@keyframes etAtomsInputSlidein {
  from {
    bottom: 20px;
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  to {
    bottom: 6px;
    opacity: 1;
  }
}
