@font-face {
  font-family: 'et-icons';
  src: url('et-icons.eot?rvhok6');
  src: url('et-icons.eot?rvhok6#iefix') format('embedded-opentype'),
    url('et-icons.ttf?rvhok6') format('truetype'),
    url('et-icons.woff?rvhok6') format('woff'),
    url('et-icons.svg?rvhok6#et-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'et-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Archive-Files:before {
  content: '\e900';
}
.icon-Browse-Files:before {
  content: '\e901';
}
.icon-Close-Pages:before {
  content: '\e902';
}
.icon-Delete:before {
  content: '\e903';
}
.icon-Doc-Combine:before {
  content: '\e904';
}
.icon-Doc-Linked:before {
  content: '\e905';
}
.icon-Doc-Send:before {
  content: '\e906';
}
.icon-Doc-Set:before {
  content: '\e907';
}
.icon-Doc-Signed:before {
  content: '\e908';
}
.icon-Doc-to-OCR:before {
  content: '\e909';
}
.icon-Doc-Unsigned:before {
  content: '\e90a';
}
.icon-Document:before {
  content: '\e90b';
}
.icon-Download:before {
  content: '\e90c';
}
.icon-Edit:before {
  content: '\e90d';
}
.icon-Expand:before {
  content: '\e90e';
}
.icon-Hide:before {
  content: '\e90f';
}
.icon-Info:before {
  content: '\e910';
}
.icon-Link:before {
  content: '\e911';
}
.icon-Open-Options:before {
  content: '\e912';
}
.icon-Open-Pages:before {
  content: '\e913';
}
.icon-Password:before {
  content: '\e914';
}
.icon-Print:before {
  content: '\e915';
}
.icon-Retreve-Files:before {
  content: '\e916';
}
.icon-Rotate-Clockwise:before {
  content: '\e917';
}
.icon-Rotate-Counter-Clockwise:before {
  content: '\e918';
}
.icon-Search:before {
  content: '\e919';
}
.icon-Select-Check:before {
  content: '\e91a';
}
.icon-Select-Open:before {
  content: '\e91b';
}
.icon-Select-X:before {
  content: '\e91c';
}
.icon-Upload:before {
  content: '\e91d';
}
.icon-User:before {
  content: '\e91e';
}
.icon-Users:before {
  content: '\e91f';
}
.icon-View:before {
  content: '\e920';
}
